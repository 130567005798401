import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScreenDimensionService {

  private renderer: Renderer2;
  public width: number = window.innerWidth;
  public height: number = window.innerHeight;

  constructor(rendererFactory: RendererFactory2) { 

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.renderer = rendererFactory.createRenderer(null, null);

    this.renderer.listen('window', 'resize', () => {
      if(environment.production) return
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    })
  }

}
